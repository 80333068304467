@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-detail {
        margin: 0 0 1px;
        .catalog-detail-holder { 
            .detail-block { 
                flex-wrap: wrap;
                .item-desc { 
                    width: 100%;
                    .item-desc-holder { 
                        padding: 21px 40px 30px;
                        border: none;
                        .item-images { 
                            width: 297px;
                            .item-images-holder { 
                                display: flex;
                                flex-direction: column-reverse;
                                .images-preview {
                                    width: 100%;
                                    height: 6.5vw;
                                    margin: 8px 0 0;
                                    padding: 0 11%;

                                    .prev, .next { 
                                        transform: rotate(270deg) translateX(-50%);
                                        bottom: auto;
                                        left: auto;
                                        top: 0;
                                        left: 0;
                                    }

                                    .next {
                                        left: auto;
                                        right: 0;
                                    }

                                    .images-preview-holder { 
                                        width: calc(100% + 10px);
                                        margin-left: -5px;
                                        .preview { 
                                            margin: 0;
                                            box-sizing: border-box;
                                            padding: 0 5px;
                                        }
                                    }
                                }

                                .image-view { 
                                    width: 100%;
                                    height: 39vw;
                                    .manufacturer { 
                                        right: 6px;
                                    }
                                }
                            }
                        }

                        .item-details { 
                            width: calc(100% - 297px);
                            box-sizing: border-box;
                            padding-left: 40px;
                            .item-details-holder { 
                                padding: 0;
                            }
                        }
                    }
                }

                .item-order { 
                    width: 100%;
                    padding: 30px 40px 23px;
                    .item-order-holder { 
                        .additional-info { 
                            .info-line { 
                                margin: 0 0 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .params { 
        .params-holder { 
            .param { 
                .name {
                    max-width: 52%;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
