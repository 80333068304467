.breadcrumbs {
  display: inline-block;
  width: 100%;
  margin-top: 31px;
  margin-bottom: 29px;
}
.breadcrumbs .breadcrumbs-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
  display: inline;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  margin-right: 6px;
  color: #888A8B;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a {
  color: #101517;
  text-decoration: none;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li a:hover {
  color: #12A356;
  transition: all 300ms;
}
.breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep {
  width: 12px;
  height: 13px;
}

@media (max-width: 1279px) {
  .breadcrumbs {
    margin-top: 21px;
    margin-bottom: 19px;
    overflow: hidden;
  }
  .breadcrumbs .breadcrumbs-holder .slick-slider .slick-list {
    overflow: visible;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li {
    margin-right: 8px;
  }
  .breadcrumbs .breadcrumbs-holder ul.breadcrumbs-list li.sep svg {
    margin: 1px 0 0;
  }
}
.pagination {
  display: inline-block;
}
.pagination ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  list-style: none;
}
.pagination ul > li {
  display: inline-block;
  float: left;
}
.pagination ul > li a {
  display: inline-block;
}
.pagination ul > li.next.disabled a, .pagination ul > li.prev.disabled a {
  color: #E0E0E0;
  pointer-events: none;
  touch-action: none;
  cursor: default;
}
.pagination ul > li.next.disabled a:hover, .pagination ul > li.prev.disabled a:hover {
  color: #E0E0E0;
  background: none;
}
.pagination ul > li.next a, .pagination ul > li.prev a {
  color: #314248;
  text-decoration: none;
  transition: all 300ms;
}
.pagination ul > li.next a svg, .pagination ul > li.prev a svg {
  width: 24px;
  height: 24px;
}
.pagination ul > li.next a:hover, .pagination ul > li.prev a:hover {
  color: black;
  transition: all 300ms;
}
.pagination ul > li.next a svg {
  transform: rotate(180deg);
}
.pagination ul > li.page {
  margin: 0 5px;
}
.pagination ul > li.page.current a {
  background: white;
  pointer-events: none;
  touch-action: none;
}
.pagination ul > li.page.current a:hover {
  cursor: default;
  background: white;
  color: #101517;
}
.pagination ul > li.page a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: none;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 160%;
  color: #101517;
  text-decoration: none;
  border-radius: 6px;
  transition: all 300ms;
}
.pagination ul > li.page a:hover {
  background: #12A356;
  color: white;
}
.detail-tabs {
  display: inline-block;
  width: 100%;
  margin: 0 0 39px;
}
.detail-tabs .detail-tabs-holder {
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 36px 40px 31px;
}
.detail-tabs .detail-tabs-holder .tabs-container {
  display: inline-block;
  width: 100%;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls {
  display: inline-block;
  width: calc(100% + 41px);
  margin: 0 -20px 23px;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls .tab-controller {
  float: left;
  margin: 0 21px;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls .tab-controller a {
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  color: #888A8B;
  position: relative;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls .tab-controller a:hover {
  color: #12A356;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls .tab-controller a:after {
  content: "";
  display: inline-block;
  width: 100%;
  height: 0;
  border-top: 1px solid #12A356;
  position: absolute;
  left: 0;
  bottom: -8px;
  z-index: 2;
  opacity: 0;
  transition: all 300ms;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls .tab-controller a.current {
  color: #101517;
  pointer-events: none;
  touch-action: none;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs-controlls .tab-controller a.current:after {
  opacity: 1;
  transition: all 300ms;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs {
  display: inline-block;
  width: 100%;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs .tab {
  display: inline-block;
  width: 100%;
  min-height: 200px;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs .tab .params {
  width: 40%;
  margin: 0;
}
.detail-tabs .detail-tabs-holder .tabs-container .tabs .tab .params .params-holder .name {
  max-width: 60%;
}

@media (max-width: 1279px) {
  .detail-tabs {
    margin: 0 0 27px;
  }
  .detail-tabs .detail-tabs-holder {
    padding: 36px 40px 20px;
  }
  .detail-tabs .detail-tabs-holder .tabs-container .tabs .tab .params {
    width: 73.8%;
  }
}
@media (max-width: 767px) {
  .detail-tabs {
    background: white;
  }
  .detail-tabs .detail-tabs-holder {
    padding: 18px 0 9px;
    border-radius: 0;
  }
  .detail-tabs .detail-tabs-holder .tabs-container .tabs .tab .params {
    width: 100%;
  }
}
.catalog-detail {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder > .name {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.catalog-detail .catalog-detail-holder > .name > * {
  margin: 0;
}
.catalog-detail .catalog-detail-holder .detail-block {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: white;
  border-radius: 6px;
  overflow: hidden;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc {
  float: left;
  width: 74.6%;
  height: auto;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 40px;
  border-right: 1px solid #F0F2F3;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images {
  float: left;
  width: 427px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview {
  float: left;
  width: 50px;
  height: 360px;
  margin-right: 17px;
  padding: 34px 0 0;
  box-sizing: border-box;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .prev, .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .next {
  transform: translateX(-50%);
  top: 0;
  right: auto;
  left: 50%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .next {
  top: auto;
  bottom: 0;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .slick-slider .slick-list .slick-track .slick-slide.slick-current > div .preview a {
  opacity: 1;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder {
  height: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder .preview {
  width: 50px;
  height: 50px;
  margin: 0 0 11px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder .preview a {
  display: inline-block;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder .preview a:hover {
  opacity: 1;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder .preview a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view {
  float: left;
  width: 360px;
  height: 360px;
  position: relative;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .manufacturer {
  position: absolute;
  z-index: 10005;
  right: 11px;
  top: 10px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .manufacturer img {
  width: 70px;
  height: 36px;
  object-fit: contain;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .image-view-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .image-view-holder .image {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .image-view-holder .image a {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .image-view-holder .image a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details {
  float: left;
  width: calc(100% - 427px);
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding-left: 50px;
  padding-right: 18px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .vendor-code {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  line-height: 160%;
  color: #888A8B;
  margin: 0 0 20px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .actions {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .actions .action {
  display: inline-block;
  width: 100%;
  margin: 0 0 9px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .actions .action a {
  display: inline-block;
  font-size: 12px;
  font-weight: 800;
  line-height: 14px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .actions .action a .text {
  float: left;
  max-width: calc(100% - 14px);
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .actions .action a .icon {
  float: left;
  width: 14px;
  height: 14px;
  color: #12A356;
}
.catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder .actions .action a .icon svg {
  width: 100%;
  height: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order {
  float: left;
  width: 25.4%;
  box-sizing: border-box;
  padding: 30px 40px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .labels {
  display: inline-block;
  width: 100%;
  margin: 0 0 15px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .labels .label {
  float: left;
  font-size: 11px;
  font-weight: 800;
  line-height: 160%;
  color: white;
  background: #DD4841;
  padding: 3px 6.5px;
  margin-right: 5px;
  border-radius: 6px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .price {
  display: inline-block;
  width: 100%;
  margin: 0 0 14px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .price .value {
  float: left;
  font-size: 24px;
  font-weight: 800;
  line-height: 140%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .amount-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 16px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .action {
  display: inline-block;
  width: 100%;
  margin: 0 0 21px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .action .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10.5px 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info {
  display: inline-block;
  width: 100%;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 140%;
  margin: 0 0 7px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line:last-of-type {
  margin: 0;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line .icon {
  float: left;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line .text {
  float: left;
  line-height: 20px;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line .text a {
  text-decoration: underline;
}
.catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line .text a:hover {
  text-decoration-color: transparent;
}

.params {
  display: inline-block;
  width: 100%;
  margin: 0 0 27px;
}
.params .params-holder {
  display: inline-block;
  width: 100%;
}
.params .params-holder .param {
  display: flex;
  font-size: 14px;
  line-height: 160%;
  margin: 0 0 5.5px;
}
.params .params-holder .param .name {
  max-width: 45%;
}
.params .params-holder .param .line {
  flex-grow: 1;
  height: 16px;
  border-bottom: 1px dashed #E6E9EC;
}
.params .params-holder .param .value {
  font-weight: 600;
}
.params .params-holder .param .value a {
  color: #12A356;
}
.params .params-holder .param .value a:hover {
  color: #008248;
}

@media (max-width: 1279px) {
  .catalog-detail {
    margin: 0 0 1px;
  }
  .catalog-detail .catalog-detail-holder .detail-block {
    flex-wrap: wrap;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc {
    width: 100%;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder {
    padding: 21px 40px 30px;
    border: none;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images {
    width: 297px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder {
    display: flex;
    flex-direction: column-reverse;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview {
    width: 100%;
    height: 6.5vw;
    margin: 8px 0 0;
    padding: 0 11%;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .prev, .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .next {
    transform: rotate(270deg) translateX(-50%);
    bottom: auto;
    left: auto;
    top: 0;
    left: 0;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .next {
    left: auto;
    right: 0;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder {
    width: calc(100% + 10px);
    margin-left: -5px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview .images-preview-holder .preview {
    margin: 0;
    box-sizing: border-box;
    padding: 0 5px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view {
    width: 100%;
    height: 39vw;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .manufacturer {
    right: 6px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details {
    width: calc(100% - 297px);
    box-sizing: border-box;
    padding-left: 40px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details .item-details-holder {
    padding: 0;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-order {
    width: 100%;
    padding: 30px 40px 23px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-order .item-order-holder .additional-info .info-line {
    margin: 0 0 5px;
  }
  .params .params-holder .param .name {
    max-width: 52%;
  }
}
@media (max-width: 767px) {
  .catalog-detail .catalog-detail-holder > .name {
    margin: 0 0 20px;
  }
  .catalog-detail .catalog-detail-holder .detail-block {
    width: 110.35%;
    margin-left: -5.175%;
    border-radius: 0;
    box-sizing: border-box;
    padding: 0 5.175%;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder {
    padding: 28px 0 0;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images {
    width: 100%;
    margin: 0 0 19px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .images-preview {
    height: 15.5vw;
    padding: 0;
    margin: 28px 0 0;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view {
    height: 81.5vw;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-images .item-images-holder .image-view .manufacturer {
    right: 9px;
    top: -3px;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-desc .item-desc-holder .item-details {
    width: 100%;
    padding: 0;
  }
  .catalog-detail .catalog-detail-holder .detail-block .item-order {
    padding: 30px 0 22px;
  }
  .params {
    margin: 0 0 17px;
  }
}
.catalog-filter {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .filter-block {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 28px;
  border-bottom: 1px solid #F0F2F3;
}
.catalog-filter .catalog-filter-holder form .filter-block:last-of-type {
  border: none;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder > .name {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-weight: 800;
  line-height: 160%;
  margin: 0 0 12px;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field {
  margin: 0;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input input {
  padding: 0px 30px 0 15px;
  font-size: 12px;
  line-height: 160%;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 160%;
}

.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input input:-moz-placeholder {
  font-size: 12px;
  line-height: 160%;
}

.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input input::-moz-placeholder {
  font-size: 12px;
  line-height: 160%;
}

.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input input:-ms-input-placeholder {
  font-size: 12px;
  line-height: 160%;
}

.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input .addon {
  right: 15px;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input .addon button {
  color: #101517;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .form-field label.input .addon button:hover {
  color: #12A356;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .checkbox-list {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .checkbox-list .checkbox-item {
  display: inline-block;
  width: 100%;
  margin: 0 0 18px;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .checkbox-list .checkbox-item:last-of-type {
  margin: 0;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .checkbox-list .checkbox-item .form-field .checkcontainer .name {
  font-size: 14px;
  line-height: 18px;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action {
  display: inline-block;
  width: 100%;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .submit {
  display: inline-block;
  width: 100%;
  margin: 0 0 14px;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .submit .btn {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  border-radius: 6px;
  padding: 13px 10px;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .reset {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .reset button {
  font-size: 14px;
  line-height: 160%;
  margin: 0;
  padding: 0;
  position: relative;
  color: #4D5153;
  background: none;
  border: none;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .reset button:hover {
  color: #12A356;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .reset button:hover:after {
  opacity: 0;
  transition: all 300ms;
}
.catalog-filter .catalog-filter-holder form .filter-block .filter-block-holder .filter-block-contain .action .reset button:after {
  content: "";
  display: inline-block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 2px;
  border-top: 1px dashed currentColor;
  transition: all 300ms;
}

.price-range {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
}
.price-range .represent-text {
  display: flex;
  justify-content: space-between;
}
.price-range .represent-text .represent-from, .price-range .represent-text .represent-to {
  float: left;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 19px;
}
.price-range .price-inputs {
  display: inline-block;
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-bottom: 0;
  position: relative;
}
.price-range .price-inputs label {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 0 5px;
  position: relative;
}
.price-range .price-inputs label .name {
  position: absolute;
  left: 21px;
  top: 14px;
  font-size: 14px;
  line-height: 160%;
}
.price-range .price-inputs label input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-family: "Manrope", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 800;
  line-height: 160%;
  color: #4D5153;
  background: #F0F2F3;
  border: 1px solid #E6E9EC;
  padding: 0 5px 0 36px;
  border-radius: 4px;
  transition: all 300ms;
}
.price-range .price-inputs label input::-webkit-input-placeholder {
  font-family: "Manrope", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #4D5153;
}

.price-range .price-inputs label input:-moz-placeholder {
  font-family: "Manrope", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #4D5153;
}

.price-range .price-inputs label input::-moz-placeholder {
  font-family: "Manrope", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #4D5153;
}

.price-range .price-inputs label input:-ms-input-placeholder {
  font-family: "Manrope", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #4D5153;
}

.price-range .price-inputs label input:focus {
  outline: none;
  border-color: #12A356;
  transition: all 300ms;
}
.price-range .slider-holder {
  display: inline-block;
  width: calc(100% - 10px);
  box-sizing: border-box;
  margin-left: 5px;
  margin-bottom: 19px;
}
.price-range .slider-holder .noUi-target {
  background: #F0F2F3;
  box-shadow: none;
  border: none;
}
.price-range .slider-holder .noUi-target.noUi-horizontal {
  height: 6px;
}
.price-range .slider-holder .noUi-target.noUi-horizontal .noUi-handle {
  width: 20px;
  height: 20px;
  right: -10px;
  top: -8px;
  border: 3px solid #12A356;
  border-radius: 50%;
  background: white;
  box-shadow: none;
  transition: all 300ms;
}
.price-range .slider-holder .noUi-target.noUi-horizontal .noUi-handle:focus, .price-range .slider-holder .noUi-target.noUi-horizontal .noUi-handle:hover {
  outline: none;
  transition: all 300ms;
}
.price-range .slider-holder .noUi-target .noUi-base {
  background: #F0F2F3;
  border-radius: 8px;
}
.price-range .slider-holder .noUi-target .noUi-connect {
  background: #12A356;
}
.price-range .slider-holder .noUi-target .noUi-handle::after, .price-range .slider-holder .noUi-target .noUi-handle::before {
  content: none;
}

.catalog-item.small-view .catalog-item-holder .image {
  height: 197px;
}
.catalog-item.small-view .catalog-item-holder .desc {
  padding: 12px 16px 0;
  height: 97px;
}
.catalog-item.small-view .catalog-item-holder .desc .desc-holder .vendor-code {
  margin: 0 0 11px;
}
.catalog-item.small-view .catalog-item-holder .desc .desc-holder .name {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.catalog-item.small-view .catalog-item-holder .order {
  height: 57px;
  padding: 0 13px 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder {
  width: 100%;
  margin: 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .price {
  width: calc(100% - 58px);
  padding: 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action {
  width: auto;
  padding: 0;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn {
  padding: 10px 10px;
  width: 58px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn .icon {
  display: block !important;
}
.catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn .text {
  display: none !important;
}

.catalog-item {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder {
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 6px;
  overflow: hidden;
}
.catalog-item .catalog-item-holder .image {
  display: inline-block;
  width: 100%;
  height: 258px;
}
.catalog-item .catalog-item-holder .image .image-holder {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
}
.catalog-item .catalog-item-holder .image .image-holder .shields {
  position: absolute;
  left: 12px;
  top: 16px;
  z-index: 2;
  max-width: 50%;
}
.catalog-item .catalog-item-holder .image .image-holder .shields .shield {
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 160%;
  padding: 3px 6px;
  background: #DD4841;
  color: white;
  float: left;
  margin: 0 3px;
  border-radius: 4px;
}
.catalog-item .catalog-item-holder .image .image-holder .mfg {
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 2;
}
.catalog-item .catalog-item-holder .image .image-holder .mfg img {
  max-width: 70px;
  max-height: 36px;
  object-fit: contain;
}
.catalog-item .catalog-item-holder .image .image-holder a {
  display: inline-block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.catalog-item .catalog-item-holder .image .image-holder a:hover {
  opacity: 0.8;
}
.catalog-item .catalog-item-holder .image .image-holder a img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.catalog-item .catalog-item-holder .desc {
  display: inline-block;
  width: 100%;
  height: 115px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 14px 25px 0;
}
.catalog-item .catalog-item-holder .desc .desc-holder {
  display: inline-block;
  width: 100%;
}
.catalog-item .catalog-item-holder .desc .desc-holder .vendor-code {
  display: inline-block;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #888A8B;
  margin: 0 0 9px;
}
.catalog-item .catalog-item-holder .desc .desc-holder .name {
  display: inline-block;
  width: 100%;
  height: 64px;
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
}
.catalog-item .catalog-item-holder .order {
  display: inline-block;
  width: 100%;
  height: 64px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 22px 0;
}
.catalog-item .catalog-item-holder .order .order-holder {
  display: flex;
  align-items: center;
  width: calc(100% + 9px);
  margin-left: -4.5px;
}
.catalog-item .catalog-item-holder .order .order-holder .price {
  float: left;
  width: 46.5%;
  box-sizing: border-box;
  padding: 0 9px;
}
.catalog-item .catalog-item-holder .order .order-holder .price .value {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 140%;
}
.catalog-item .catalog-item-holder .order .order-holder .action {
  float: right;
  width: 53.5%;
  box-sizing: border-box;
  padding: 0 9px;
}
.catalog-item .catalog-item-holder .order .order-holder .action .btn {
  width: 100%;
  text-align: center;
  padding: 6px 10px;
}

@media (max-width: 1279px) {
  .catalog-item.small-view .catalog-item-holder .image {
    height: 132px;
  }
  .catalog-item.small-view .catalog-item-holder .image .image-holder .shields {
    left: 5px;
    top: 7px;
  }
  .catalog-item.small-view .catalog-item-holder .image .image-holder .mfg {
    right: auto;
    top: auto;
    left: 12px;
    bottom: -24px;
  }
  .catalog-item.small-view .catalog-item-holder .image .image-holder .mfg img {
    max-width: 50px;
    max-height: 25px;
  }
  .catalog-item.small-view .catalog-item-holder .desc {
    padding: 31px 12px 0;
    height: 111px;
  }
  .catalog-item.small-view .catalog-item-holder .desc .desc-holder .vendor-code {
    font-size: 11px;
    margin: 0 0 8px;
  }
  .catalog-item.small-view .catalog-item-holder .order {
    padding: 0 9px 0;
    height: 41px;
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .price {
    width: calc(100% - 34px);
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .price .value {
    font-size: 16px;
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn {
    width: 34px;
    height: 30px;
    padding: 1px 1px;
  }
  .catalog-item.small-view .catalog-item-holder .order .order-holder .action .btn .icon svg {
    width: 18px;
    height: 18px;
  }
  .catalog-item .catalog-item-holder .image {
    height: 26vw;
  }
  .catalog-item .catalog-item-holder .desc {
    padding: 10px 16px 0;
    height: 94px;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .name {
    font-size: 12px;
    line-height: 140%;
    height: 50px;
  }
  .catalog-item .catalog-item-holder .order {
    height: 57px;
    padding: 0 12px 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder {
    width: 100%;
    margin: 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder .price {
    width: calc(100% - 58px);
    padding: 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action {
    width: 58px;
    padding: 0;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn {
    padding: 10px 18px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn .icon {
    width: 22px;
    height: 22px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn .icon svg {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 767px) {
  .catalog-item.small-view .catalog-item-holder .desc {
    padding: 13px 11px 0;
    height: 92px;
  }
  .catalog-item.small-view .catalog-item-holder .order {
    height: 42px;
  }
  .catalog-item .catalog-item-holder .image {
    height: 40vw;
    margin: 0 0 20px;
  }
  .catalog-item .catalog-item-holder .image .image-holder .shields {
    left: 3px;
    top: 4px;
  }
  .catalog-item .catalog-item-holder .image .image-holder .mfg {
    right: auto;
    top: auto;
    bottom: -25px;
    left: 10px;
  }
  .catalog-item .catalog-item-holder .image .image-holder .mfg img {
    max-width: 50px;
    max-height: 26px;
  }
  .catalog-item .catalog-item-holder .desc {
    padding: 14px 9px 0;
  }
  .catalog-item .catalog-item-holder .desc .desc-holder .vendor-code {
    font-size: 11px;
  }
  .catalog-item .catalog-item-holder .order {
    padding: 0 9px 0;
    height: 41px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .price {
    width: calc(100% - 34px);
  }
  .catalog-item .catalog-item-holder .order .order-holder .price .value {
    font-size: 16px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action {
    width: 34px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn {
    padding: 4px 7px;
  }
  .catalog-item .catalog-item-holder .order .order-holder .action .btn .icon {
    width: 18px;
    height: 18px;
  }
}
.items-sider-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 57px;
}
.items-sider-block.small-gap {
  margin: 0 0 37px;
}
@media (max-width: 1279px) {
  .items-sider-block {
    overflow: hidden;
    margin: 0 0 25px;
  }
  .items-sider-block .items-slider-block-holder .items-slider {
    padding: 0 0 19px;
  }
  .items-sider-block .items-slider-block-holder .slick-slider .slick-list {
    overflow: visible;
  }
}
.catalog-column-view {
  display: inline-block;
  width: 100%;
  margin: 0 0 55px;
}
.catalog-column-view .catalog-column-view-holder {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.catalog-column-view .catalog-column-view-holder .column {
  float: left;
  width: 25%;
  box-sizing: border-box;
  padding: 0 10px;
}
.catalog-column-view .catalog-column-view-holder .column .column-holder {
  display: inline-block;
  width: 100%;
  display: inline-block;
  border-radius: 6px;
  width: 100%;
  background: white;
  overflow: hidden;
}
.catalog-column-view .catalog-column-view-holder .catalog-view {
  float: left;
  width: 75%;
  box-sizing: border-box;
  padding: 0 10px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder {
  display: inline-block;
  width: 100%;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 15px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  background: #FFF;
  box-sizing: border-box;
  padding: 10px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search {
  width: calc(100% - 46px);
  float: left;
  box-sizing: border-box;
  padding-right: 10px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field {
  margin: 0;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field .input input {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  height: 40px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field .input input::-webkit-input-placeholder {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field .input input:-moz-placeholder {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field .input input::-moz-placeholder {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field .input input:-ms-input-placeholder {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .vendor-code-search form .form-field .input .addon {
  right: 18px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .filter-button {
  width: 46px;
  height: 40px;
  float: left;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .filter-button .btn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 7px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .mobile-filter-block .mobile-filter-block-holder .filter-button .btn svg {
  width: 100%;
  height: 100%;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options {
  float: right;
  margin: -3px -4px 11px 0;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form {
  display: inline-block;
  width: auto;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field {
  display: inline-block;
  width: auto;
  margin: 0;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select {
  width: auto;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .name {
  float: left;
  width: auto;
  font-size: 12px;
  font-family: Manrope;
  line-height: 24px;
  color: #888A8B;
  margin: 0 3px 0 0;
  position: absolute;
  left: 0;
  top: 0;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select {
  float: left;
  padding: 0 0 0 82px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric {
  background: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  height: auto;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric .label {
  font-size: 12px;
  font-family: Manrope;
  line-height: 24px;
  margin: 0 -1px 0 0;
  width: auto;
  height: auto;
  float: left;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric .button {
  position: relative;
  left: auto;
  top: auto;
  float: left;
  height: 24px;
  width: 24px;
  border: none;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric .button:hover {
  background: transparent;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric .button:after {
  background-image: url(../img/arrow-24.svg);
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items {
  width: 100% !important;
  border-radius: 6px;
  background: #FFF;
  /* shadow-1 */
  box-shadow: 0px 10px 16px 0px rgba(77, 84, 87, 0.1);
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items .selectric-scroll {
  width: 100%;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items .selectric-scroll ul {
  width: 100%;
  max-height: 310px;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items .selectric-scroll ul li {
  width: 100%;
  min-height: auto;
  padding: 6px 12px;
  box-sizing: border-box;
  font-size: 12px;
  font-family: Manrope;
  line-height: 140%;
  color: #101517;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items .selectric-scroll ul li:hover {
  color: #12A356;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items .selectric-scroll ul li.selected {
  background: #12A356;
  color: white;
}
.catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options form .form-field label.select .selectric-selectric-select .selectric-items .selectric-scroll ul li.selected:hover {
  color: white;
}

.side-menu {
  display: inline-block;
  width: 100%;
}
.side-menu nav {
  display: inline-block;
  width: 100%;
}
.side-menu nav ul {
  display: inline-block;
  width: 100%;
  margin: 0;
}
.side-menu nav ul li {
  display: inline-block;
  width: 100%;
}
.side-menu nav ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 160%;
  padding: 12px 14px 12px 28px;
  box-sizing: border-box;
  border-bottom: 1px solid #F0F2F3;
}
.side-menu nav ul li a:hover {
  background-color: #12A356;
  color: white;
}
.side-menu nav ul li a:hover .icon {
  color: white;
  transition: all 300ms;
}
.side-menu nav ul li a .text {
  max-width: 80%;
}
.side-menu nav ul li a .icon {
  width: 16px;
  height: 16px;
  color: #12A356;
  transition: all 300ms;
}
.side-menu nav ul li a .icon svg {
  width: 100%;
  height: 100%;
}

.catalog-list.col-3 .catalog-item {
  width: 33.333%;
}

.catalog-list {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -9px;
}
.catalog-list .catalog-item {
  width: 25%;
  float: left;
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 0 17px;
}

@media (max-width: 1279px) {
  .catalog-column-view {
    margin: 0 0 25px;
  }
  .catalog-column-view .catalog-column-view-holder {
    width: 100%;
    margin: 0;
  }
  .catalog-column-view .catalog-column-view-holder .column {
    width: 100%;
    padding: 0;
  }
  .catalog-column-view .catalog-column-view-holder .catalog-view {
    width: 100%;
    padding: 0;
  }
  .catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options {
    margin: 0 0 15px;
    float: left;
  }
  .catalog-list {
    width: calc(100% + 15px);
    margin-left: -7.5px;
  }
  .catalog-list .catalog-item {
    padding: 0 7.5px;
    margin: 0 0 11px;
  }
  .side-menu {
    margin: 0 0 10px;
  }
  .side-menu nav ul {
    width: calc(100% + 8px);
    margin-left: -4px;
  }
  .side-menu nav ul li {
    width: auto;
    float: left;
    margin: 0 4px 7px;
  }
  .side-menu nav ul li a {
    width: auto;
    padding: 8px 8px;
    background: white;
    border-radius: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }
}
@media (max-width: 767px) {
  .catalog-column-view {
    margin: 0 0 24px;
  }
  .catalog-column-view .catalog-column-view-holder .catalog-view .catalog-view-holder .view-options {
    margin: 0 0 15px 4px;
  }
  .catalog-list {
    width: calc(100% + 6px);
    margin-left: -3px;
  }
  .catalog-list .catalog-item {
    padding: 0 3px;
    margin: 0 0 5px;
  }
  .catalog-list.col-xs-2 .catalog-item {
    width: 50%;
  }
}
.catalog-root-block.vertical-overlap {
  display: block;
  transform: translateY(-72px);
  margin: 0 0 -14px;
}

.catalog-root-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 40px;
}
.catalog-root-block .catalog-root-block-holder {
  display: inline-block;
  width: 100%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  overflow: hidden;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category.gray .category-holder {
  background: #E6E9EC;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category {
  width: 50%;
  float: left;
  box-sizing: border-box;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder {
  display: inline-block;
  width: 100%;
  height: 280px;
  background: white;
  position: relative;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image {
  display: inline-block;
  width: 210px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains {
  display: inline-block;
  width: 79%;
  height: 79%;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  padding: 30px 40px 0px;
  overflow: hidden;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name {
  margin: 0 0 24px;
  width: 110%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name a span {
  color: #12A356;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul.col-2 li {
  width: 50%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul.col-3 li {
  width: 33.333%;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul {
  display: inline-block;
  width: calc(100% + 14px);
  margin-left: -7px;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories ul li {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 0 7px;
  margin: 0 0 22px;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all {
  position: absolute;
  left: 40px;
  bottom: 22px;
  z-index: 3;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all a {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #12A356;
  text-decoration: underline;
  text-decoration-color: currentColor;
}
.catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all a:hover {
  color: #008248;
  text-decoration-color: transparent;
}

@media (max-width: 1279px) {
  .catalog-root-block.vertical-overlap {
    transform: translateY(-47px);
    margin: 0 0 -18px;
  }
  .catalog-root-block {
    margin: 0 0 29px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder {
    height: 125px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image {
    width: auto;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 2px 100px 0 40px;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name {
    margin: 0;
    font-size: 16px;
    line-height: 160%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .sub-categories {
    display: none;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .show-all {
    display: none;
  }
}
@media (max-width: 767px) {
  .catalog-root-block.vertical-overlap {
    transform: none;
    margin: 0 0 -3px;
  }
  .catalog-root-block .catalog-root-block-holder {
    width: 110.35%;
    margin-left: -5.175%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category.gray .category-holder {
    background: white;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category:nth-of-type(2n + 1) .category-holder {
    background: #E6E9EC !important;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category {
    width: 100%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder {
    height: 82px;
    overflow: hidden;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-image {
    top: -9%;
    height: 116%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains {
    padding: 0px 85px 0 9.5%;
  }
  .catalog-root-block .catalog-root-block-holder .catalog-categories .category .category-holder .category-contains .name {
    line-height: 160%;
  }
}
.search-result-info {
  display: inline-block;
  width: 100%;
  margin: 0 0 31px;
}
.search-result-info .search-result-info-holder {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.search-result-info .search-result-info-holder .result {
  display: inline-block;
  width: 100%;
}
.search-result-info .search-result-info-holder .note {
  display: inline-block;
  width: 100%;
  margin: 12px 0 0;
}
.search-result-info .search-result-info-holder .action {
  display: inline-block;
  width: 100%;
  margin: 18px 0 20px;
}
.search-result-info .search-result-info-holder .action .btn {
  padding: 15px 43px;
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}

@media (max-width: 1279px) {
  .search-result-info {
    margin: 0 0 19px;
  }
  .search-result-info .search-result-info-holder {
    font-size: 14px;
  }
  .search-result-info .search-result-info-holder .note {
    margin: 10px 0 0;
  }
  .search-result-info .search-result-info-holder .action {
    margin: 20px 0 8px;
  }
  .search-result-info .search-result-info-holder .action .btn {
    padding: 15px 73px;
  }
}
@media (max-width: 767px) {
  .search-result-info .search-result-info-holder .action .btn {
    width: 100%;
    text-align: center;
    padding: 15px 10px;
  }
}
.service-order {
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 30px;
  overflow: hidden;
}
.service-order .service-order-holder {
  display: inline-block;
  width: 100%;
}
.service-order .service-order-holder .heading {
  display: inline-block;
  width: 100%;
}
.service-order .service-order-holder form .form-group .form-col .form-field {
  margin: 0 0 17px;
}
.service-order .service-order-holder form .form-group .form-col .form-field .input textarea {
  height: 100px;
}
.service-order .service-order-holder form .action {
  margin: -4px 0 0;
}
.service-order .service-order-holder form .action .submit .btn {
  padding: 13px 10px;
}
@media (max-width: 1279px) {
  .service-order {
    padding: 30px 40px;
  }
  .service-order .service-order-holder .heading {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    margin: 0 0 16px;
  }
}
@media (max-width: 767px) {
  .service-order {
    padding: 30px 4.69% 30px;
  }
  .service-order .service-order-holder .heading {
    margin: 0 0 13px;
  }
}
.services-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 76px;
}
.services-block .services-block-holder {
  display: inline-block;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.services-block .services-block-holder .services-list-block {
  float: left;
  width: calc(100% - 306px);
  box-sizing: border-box;
  padding: 0 10px;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list {
  display: inline-block;
  width: 100%;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  margin: 0 0 21px;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service:last-of-type .service-holder a {
  border: none;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder {
  display: inline-block;
  width: 100%;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 142px;
  box-sizing: border-box;
  padding: 16px 40px;
  border-bottom: 1px solid #F0F2F3;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a .icon {
  width: 68px;
  height: 68px;
}
.services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a .text {
  width: calc(100% - 68px);
  box-sizing: border-box;
  padding-left: 40px;
}
.services-block .services-block-holder .add-col {
  float: left;
  width: 306px;
  box-sizing: border-box;
  padding: 0 10px;
}
.services-block .services-block-holder .add-col .add-col-holder {
  display: inline-block;
  width: 100%;
}
@media (max-width: 1279px) {
  .services-block {
    margin: 0 0 39px;
  }
  .services-block .services-block-holder {
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .services-block .services-block-holder .services-list-block {
    width: 100%;
    padding: 0;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list {
    background: transparent;
    border-radius: 0;
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service {
    float: left;
    width: 50%;
    box-sizing: border-box;
    padding: 0 10px;
    margin: 0 0 22px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    border: none;
    height: auto;
    flex-wrap: wrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    padding: 18px 30px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a .icon {
    width: 100%;
    margin: 0 0 22px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a .text {
    width: 100%;
    padding: 0;
    height: 104px;
    overflow: hidden;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .controlls {
    margin: -25px 0 0;
  }
  .services-block .services-block-holder .add-col {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 767px) {
  .services-block {
    margin: 0 0 27px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list {
    width: 110.35%;
    margin-left: -5.175%;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service {
    width: 100%;
    padding: 0;
    margin: 0 0 2px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a {
    padding: 18px calc(4.69% + 15px) 18px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .services-list .service .service-holder a .text {
    height: 110px;
  }
  .services-block .services-block-holder .services-list-block .services-list-block-holder .controlls {
    margin: -4px 0 0;
  }
}
.amount {
  display: inline-block;
  width: 100%;
}
.amount .amount-holder {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid #E6E9EC;
  box-sizing: border-box;
  padding: 0 13px;
}
.amount .amount-holder a {
  width: 24px;
  height: 24px;
}
.amount .amount-holder a svg {
  width: 100%;
  height: 100%;
}
.amount .amount-holder input {
  width: calc(100% - 48px);
  background: none;
  border: none;
  text-align: center;
  font-size: 16px;
  line-height: 140%;
  font-weight: 800;
}

.company-points-block {
  display: inline-block;
  width: 100%;
  background: #314248;
  color: white;
}
.company-points-block .company-points-block-holder {
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  padding: 54px 2.5%;
}
.company-points-block .company-points-block-holder .points {
  display: flex;
  width: calc(100% + 120px);
  margin-left: -60px;
}
.company-points-block .company-points-block-holder .points .point {
  float: left;
  width: 33.333%;
  box-sizing: border-box;
  padding: 0 60px;
}
.company-points-block .company-points-block-holder .points .point:nth-of-type(3n) .point-holder:after {
  display: none;
}
.company-points-block .company-points-block-holder .points .point .point-holder {
  display: flex;
  align-items: center;
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  position: relative;
}
.company-points-block .company-points-block-holder .points .point .point-holder:after {
  content: "";
  display: inline-block;
  width: 0;
  height: 100%;
  opacity: 0.1;
  border-left: 1px solid white;
  position: absolute;
  right: -60px;
  top: 0;
}
.company-points-block .company-points-block-holder .points .point .point-holder .icon {
  width: 40px;
  height: 40px;
  float: left;
}
.company-points-block .company-points-block-holder .points .point .point-holder .icon svg {
  width: 100%;
  height: 100%;
}
.company-points-block .company-points-block-holder .points .point .point-holder .text {
  width: calc(100% - 40px);
  float: left;
  box-sizing: border-box;
  padding-left: 30px;
}

@media (max-width: 1279px) {
  .company-points-block .company-points-block-holder {
    padding: 35px 0 36px;
  }
  .company-points-block .company-points-block-holder .points {
    width: calc(100% + 35px);
    margin-left: -17.5px;
  }
  .company-points-block .company-points-block-holder .points .point {
    padding: 0 17.5px;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder {
    font-size: 14px;
    line-height: 160%;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder:after {
    display: none;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder .text {
    padding-left: 20px;
  }
}
@media (max-width: 767px) {
  .company-points-block .company-points-block-holder {
    padding: 31px 0 16px;
  }
  .company-points-block .company-points-block-holder .points {
    width: 100%;
    margin-left: 0;
    display: inline-block;
  }
  .company-points-block .company-points-block-holder .points .point {
    padding: 0;
    width: 100%;
    margin: 0 0 9px;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder {
    font-size: 14px;
    line-height: 160%;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder:after {
    display: none;
  }
  .company-points-block .company-points-block-holder .points .point .point-holder .text {
    padding-left: 20px;
  }
}
.controlls {
  display: inline-block;
  width: 100%;
  margin: -3px 0 0;
}
.controlls .show-more {
  display: inline-block;
  width: 100%;
  margin: 0 0 21px;
}
.controlls .show-more .btn {
  display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  padding: 13px 10px;
}
.controlls .pagination-block {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1279px) {
  .controlls {
    margin: 4px 0 0;
  }
}
@media (max-width: 767px) {
  .controlls {
    margin: 11px 0 0;
  }
}
.common-heading-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 30px;
}
.common-heading-block .common-heading-block-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.common-heading-block .common-heading-block-holder .text {
  float: left;
  width: 90%;
  margin: 0;
}
.common-heading-block .common-heading-block-holder .text .text-contains {
  display: inline;
}
.common-heading-block .common-heading-block-holder .text .icon {
  display: inline;
  color: #12A356;
}
.common-heading-block .common-heading-block-holder .text .icon svg {
  width: 24px;
  height: 24px;
  margin: 9px 0 0 10px;
}
.common-heading-block .common-heading-block-holder .action {
  width: 10%;
  float: right;
  display: flex;
  justify-content: flex-end;
  margin-right: -7px;
}
.common-heading-block .common-heading-block-holder .action .prev, .common-heading-block .common-heading-block-holder .action .next {
  float: left;
  position: relative;
  left: auto;
  right: auto;
  top: auto;
  transform: none;
  margin: 0 7px;
}
@media (max-width: 1279px) {
  .common-heading-block {
    margin: 0 0 17px;
  }
  .common-heading-block .common-heading-block-holder .text .icon svg {
    margin: 6px 0 0 10px;
  }
}
@media (max-width: 767px) {
  .common-heading-block {
    margin: 0 0 20px;
  }
  .common-heading-block .common-heading-block-holder .text {
    width: 95%;
  }
  .common-heading-block .common-heading-block-holder .text .icon svg {
    margin: 7px 0 0 5px;
  }
  .common-heading-block .common-heading-block-holder .action {
    display: none;
  }
}
.text-block.plain {
  margin: 0;
}
.text-block.plain .text-block-holder {
  padding: 0;
}

.text-block {
  display: inline-block;
  width: 100%;
  margin: 0 0 34px;
}
.text-block .text-block-holder {
  display: inline-block;
  width: 100%;
  border-radius: 6px;
  background: white;
  box-sizing: border-box;
  padding: 40px;
}
.text-block .text-block-holder h2, .text-block .text-block-holder .h2, .text-block .text-block-holder h3, .text-block .text-block-holder .h3 {
  margin: 0 0 13px;
}
.text-block .text-block-holder p {
  color: #4D5153;
  margin: 0 0 11px;
}
.text-block .text-block-holder ol.policy {
  display: inline-block;
  width: 100%;
  counter-reset: section;
  list-style: none;
  padding-left: 0;
  margin: 18px 0 0;
}
.text-block .text-block-holder ol.policy > li {
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
  counter-reset: section2, section3;
  margin: 0 0 21px;
}
.text-block .text-block-holder ol.policy > li:last-of-type {
  margin: 0;
}
.text-block .text-block-holder ol.policy > li::before {
  counter-increment: section;
  content: counter(section) ".";
  display: inline;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 140%;
}
.text-block .text-block-holder ol.policy > li ol, .text-block .text-block-holder ol.policy > li ul {
  display: inline-block;
  width: 100%;
  list-style: none;
  padding-left: 0;
  margin: 0;
}
.text-block .text-block-holder ol.policy > li ol li, .text-block .text-block-holder ol.policy > li ul li {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: #4D5153;
  position: relative;
  margin: 0 0 9px;
}
.text-block .text-block-holder ol.policy > li ol li::before, .text-block .text-block-holder ol.policy > li ul li::before {
  display: inline;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  color: #4D5153;
}
.text-block .text-block-holder ol.policy > li ul {
  margin: 10px 0 0;
}
.text-block .text-block-holder ol.policy > li > ol {
  counter-reset: section2;
  margin: 15px 0 0;
}
.text-block .text-block-holder ol.policy > li > ol > li::before {
  counter-increment: section2;
  content: counter(section) "." counter(section2) ".";
}
.text-block .text-block-holder ol.policy > li > ol > li > ol {
  counter-reset: section3;
  margin: 11px 0 0;
}
.text-block .text-block-holder ol.policy > li > ol > li > ol > li:last-of-type {
  margin: 0;
}
.text-block .text-block-holder ol.policy > li > ol > li > ol > li::before {
  counter-increment: section3;
  content: counter(section) "." counter(section2) "." counter(section3) ".";
}
.text-block .text-block-holder ol.policy > li > ol > li > ol > li > * {
  display: inline;
}

@media (max-width: 1279px) {
  .text-block.alt .text-block-holder p {
    line-height: 158%;
  }
  .text-block {
    margin: 0 0 40px;
  }
  .text-block .text-block-holder {
    padding: 30px 40px 22px;
  }
  .text-block .text-block-holder h2, .text-block .text-block-holder .h2, .text-block .text-block-holder h3, .text-block .text-block-holder .h3 {
    margin: 0 0 15px;
  }
  .text-block .text-block-holder p {
    margin: 0 0 10px;
  }
}
@media (max-width: 767px) {
  .text-block {
    margin: 0 0 38px;
    background: white;
  }
  .text-block .text-block-holder {
    padding: 28px 0 22px;
    border-radius: 0;
  }
  .text-block .text-block-holder h2, .text-block .text-block-holder .h2, .text-block .text-block-holder h3, .text-block .text-block-holder .h3 {
    margin: 0 0 15px;
  }
  .text-block .text-block-holder p {
    margin: 0 0 10px;
  }
}