@media (max-width: $screen-lg) {
    // lg-md view
    .detail-tabs { 
        margin: 0 0 27px;
        .detail-tabs-holder { 
            padding: 36px 40px 20px;
            .tabs-container { 
                
                .tabs { 
                    .tab { 
                        .params {
                            width: 73.8%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
