.text-block.plain {
    margin: 0;
    .text-block-holder { 
        padding: 0;
    }
}
.text-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 34px;
	.text-block-holder { 
        display: inline-block;
        width: 100%;
        border-radius: 6px;
        background: white;
        box-sizing: border-box;
        padding: 40px;
        h2, .h2, h3, .h3 { 
            margin: 0 0 13px;
        }

        p { 
            color: $gray-dark;
            margin: 0 0 11px;
        }

        ol.policy { 
            display: inline-block;
            width: 100%;
            counter-reset: section;
            list-style: none;
            padding-left: 0;
            margin: 18px 0 0;
            >li { 
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: 140%;
                counter-reset: section2, section3;
                margin: 0 0 21px;
                &:last-of-type {
                    margin: 0;
                }
                &::before {
                    counter-increment: section;
                    content: counter(section) ".";
                    display: inline;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: 140%;
                }
                ol, ul {
                    display: inline-block;
                    width: 100%;
                    list-style: none;
                    padding-left: 0;
                    margin: 0;
                    li {
                        display: inline-block;
                        width: 100%;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 160%;
                        color: $gray-dark;
                        position: relative;
                        margin: 0 0 9px;
                        &::before {
                            display: inline;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 160%;
                            color: $gray-dark;
                        }
                    }
                }
                ul {
                    margin: 10px 0 0;
                }
                >ol { 
                    counter-reset: section2;
                    margin: 15px 0 0;
                    >li { 
                        &::before {
                            counter-increment: section2;
                            content: counter(section) "." counter(section2) ".";
                        }
                        >ol {
                            counter-reset: section3;
                            margin: 11px 0 0;
                            >li {
                                &:last-of-type {
                                    margin: 0;
                                }
                                &::before {
                                    counter-increment: section3;
                                    content: counter(section) "." counter(section2) "." counter(section3) ".";
                                }
                                >* {
                                    display: inline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/common/text_block";
@import "../../media/mobile/includes/common/text_block";
