@media (max-width: $screen-lg) {
    // lg-md view
    .service-order { 
        padding: 30px 40px;
        .service-order-holder { 
            .heading {
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 800;
                line-height: 140%;
                margin: 0 0 16px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
