@media (max-width: $screen-sm) {
    // sm-view
    .controlls {
        margin: 11px 0 0;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
