.amount { 
    display: inline-block;
    width: 100%;
	.amount-holder { 
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        border: 1px solid $gray-light;
        box-sizing: border-box;
        padding: 0 13px;
		a { 
            width: 24px;
            height: 24px;
			svg { 
                width: 100%;
                height: 100%;
			}
		}

		input { 
            width: calc(100% - 24px*2);
            background: none;
            border: none;
            text-align: center;
            font-size: 16px;
            line-height: 140%;
            font-weight: 800;
		}
	}
}