@media (max-width: $screen-lg) {
    // lg-md view
    .text-block.alt {
        .text-block-holder { 
            p {
                line-height: 158%;
            }
        }
    }
    .text-block { 
        margin: 0 0 40px;
        .text-block-holder { 
            padding: 30px 40px 22px;
            h2, .h2, h3, .h3 {
                margin: 0 0 15px;
            }

            p {
                margin: 0 0 10px;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
