@media (max-width: $screen-sm) {
    // sm-view
    .search-result-info { 
        .search-result-info-holder { 
            .action {
                .btn {
                    width: 100%;
                    text-align: center;
                    padding: 15px 10px;
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
