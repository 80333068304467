@media (max-width: $screen-sm) {
    // sm-view
    .services-block { 
        margin: 0 0 27px;
        .services-block-holder { 
            .services-list-block { 
                .services-list-block-holder { 
                    .services-list { 
                        width: 110.35%;
                        margin-left: -5.175%;
                        .service { 
                            width: 100%;
                            padding: 0;
                            margin: 0 0 2px;
                            .service-holder { 
                                a {
                                    padding: 18px calc(4.69% + 15px) 18px;
                                    .text {
                                        height: 110px;
                                    }
                                }
                            }
                        }

                    }

                    .controlls { 
                        margin: -4px 0 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
