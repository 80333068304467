@media (max-width: $screen-lg) {
    // lg-md view
    .search-result-info { 
        margin: 0 0 19px;
        .search-result-info-holder { 
            font-size: 14px;
            .result { 

            }

            .note {
                margin: 10px 0 0;
            }

            .action {
                margin: 20px 0 8px;
                .btn {
                    padding: 15px 73px;
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
