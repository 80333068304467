.detail-tabs { 
    display: inline-block;
    width: 100%;
    margin: 0 0 39px;
	.detail-tabs-holder { 
        display: inline-block;
        width: 100%;
        background: white;
        border-radius: 6px;
        box-sizing: border-box;
        padding: 36px 40px 31px;
        .tabs-container { 
            display: inline-block;
            width: 100%;
            .tabs-controlls { 
                display: inline-block;
                width: calc(100% + 41px);
                margin: 0 -20px 23px;
                .tab-controller { 
                    float: left;
                    margin: 0 21px;
                    a {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 160%;
                        color: $gray;
                        position: relative;
                        &:hover {
                            color: $brand-primary;
                        }
                        &:after {
                            content: "";
                            display: inline-block;
                            width: 100%;
                            height: 0;
                            border-top: 1px solid $brand-primary;
                            position: absolute;
                            left: 0;
                            bottom: -8px;
                            z-index: 2;
                            opacity: 0;
                            transition: all 300ms;
                        }
                        &.current { 
                            color: $gray-base;
                            pointer-events: none;
                            touch-action: none;
                            &:after {
                                opacity: 1;
                                transition: all 300ms;
                            }
                        }

                    }

                }
            }

            .tabs { 
                display: inline-block;
                width: 100%;
                .tab { 
                    display: inline-block;
                    width: 100%;
                    min-height: 200px;
                    .params {
                        width: 40%;
                        margin: 0;
                        .params-holder { 
                            .name {
                                max-width: 60%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/detail_tabs";
@import "../../media/mobile/includes/catalog/detail_tabs";
