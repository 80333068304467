@media (max-width: $screen-sm) {
    // sm-view
    .catalog-detail {
        .catalog-detail-holder { 
            >.name {
                margin: 0 0 20px;
            }
            .detail-block { 
                width: 110.35%;
                margin-left: -5.175%;
                border-radius: 0;
                box-sizing: border-box;
                padding: 0 5.175%;
                .item-desc { 
                    .item-desc-holder { 
                        padding: 28px 0 0;
                        .item-images { 
                            width: 100%;
                            margin: 0 0 19px;
                            .item-images-holder { 

                                .images-preview {
                                    height: 15.5vw;
                                    padding: 0;
                                    margin: 28px 0 0;
                                }

                                .image-view { 
                                    height: 81.5vw;
                                    .manufacturer { 
                                        right: 9px;
                                        top: -3px;
                                    }
                                }
                            }
                        }

                        .item-details { 
                            width: 100%;
                            padding: 0;
                        }
                    }
                }

                .item-order { 
                    padding: 30px 0 22px;
                }
            }
        }
    }

    .params { 
        margin: 0 0 17px;
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
