.breadcrumbs {
    display: inline-block;
    width: 100%;
    margin-top: 31px;
    margin-bottom: 29px;
    .breadcrumbs-holder {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        ul.breadcrumbs-list {
            display: inline-block;
            width: 100%;
            margin: 0;
            li {
                display: inline;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 140%;
                margin-right: 6px;
                color: $gray;
                a {
                    color: $gray-base;
                    text-decoration: none;
                    transition: all 300ms;
                    &:hover {
                        color: $brand-primary;
                        transition: all 300ms;
                    }
                }
                span {

                }
                &.sep {
                    width: 12px;
                    height: 13px;
                }
            }
        }
    }
}

@import "../media/tablet/includes/breadcrumbs";
@import "../media/mobile/includes/breadcrumbs";
