.services-block { 
    display: inline-block;
    width: 100%;
    margin: 0 0 76px;
	.services-block-holder { 
        display: inline-block;
        width: calc(100% + 20px);
        margin-left: -10px;
        .services-list-block { 
            float: left;
            width: calc(100% - 306px);
            box-sizing: border-box;
            padding: 0 10px;
            .services-list-block-holder { 
                display: inline-block;
                width: 100%;
                .services-list { 
                    display: inline-block;
                    width: 100%;
                    background: white;
                    border-radius: 6px;
                    overflow: hidden;
                    margin: 0 0 21px;
                    .service { 
                        display: inline-block;
                        width: 100%;
                        &:last-of-type {
                            .service-holder { 
                                a {
                                    border: none;
                                }
                            }
                        }
                        .service-holder { 
                            display: inline-block;
                            width: 100%;
                            a { 
                                display: flex;
                                align-items: center;
                                width: 100%;
                                height: 142px;
                                box-sizing: border-box;
                                padding: 16px 40px;
                                border-bottom: 1px solid $bg-secondary;
                                font-size: 18px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 160%;
                                .icon { 
                                    width: 68px;
                                    height: 68px;
                                    svg { 

                                    }
                                }

                                .text { 
                                    width: calc(100% - 68px);
                                    box-sizing: border-box;
                                    padding-left: 40px;
                                }
                            }
                        }
                    }
                }

                .controlls { 

                    .show-more { 

                        .btn { 

                        }
                    }

                    .pagination-block { 

                    }
                }
            }
        }

        .add-col { 
            float: left;
            width: 306px;
            box-sizing: border-box;
            padding: 0 10px;
            .add-col-holder { 
                display: inline-block;
                width: 100%;

                .small-news-list-block { 

                    .small-news-list-block-holder { 

                        .heading { 

                            a { 

                                .text { 

                                }

                                .icon { 

                                    svg { 

                                        path { 

                                        }
                                    }
                                }
                            }
                        }

                        .small-news-list { 

                            .small-news-item { 

                                .small-new-item-holder { 

                                    .image { 

                                        .image-holder { 

                                            a { 

                                                img { 

                                                }
                                            }
                                        }
                                    }

                                    .desc { 

                                        .desc-holder { 

                                            .date { 

                                            }

                                            .name { 

                                                a { 

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/services_list";
@import "../../media/mobile/includes/catalog/services_list";
