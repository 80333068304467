.search-result-info { 
    display: inline-block;
    width: 100%;
    margin: 0 0 31px;
	.search-result-info-holder { 
        display: inline-block;
        width: 100%;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%;
        .result { 
            display: inline-block;
            width: 100%;
            strong { 

            }
        }

        .note {
            display: inline-block;
            width: 100%;
            margin: 12px 0 0;
        }

        .action {
            display: inline-block;
            width: 100%;
            margin: 18px 0 20px;
            .btn {
                padding: 15px 43px;
                text-transform: uppercase;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 160%;
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/search_results";
@import "../../media/mobile/includes/catalog/search_results";
