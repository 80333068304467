.catalog-column-view { 
    display: inline-block;
    width: 100%;
    margin: 0 0 55px;
	.catalog-column-view-holder { 
        display: inline-block;
        width: calc(100% + 20px);
        margin-left: -10px;
        .column { 
            float: left;
            width: 25%;
            box-sizing: border-box;
            padding: 0 10px;
            .column-holder { 
                display: inline-block;
                width: 100%;
                display: inline-block;
                border-radius: 6px;
                width: 100%;
                background: white;
                overflow: hidden;
            }
        }

        .catalog-view { 
            float: left;
            width: 75%;
            box-sizing: border-box;
            padding: 0 10px;
            .catalog-view-holder { 
                display: inline-block;
                width: 100%;

                .mobile-filter-block { 
                    display: inline-block;
                    width: 100%;
                    margin: 0 0 15px;
                    .mobile-filter-block-holder { 
                        display: inline-block;
                        width: 100%;
                        border-radius: 6px;
                        background: #FFF;
                        box-sizing: border-box;
                        padding: 10px;
                        .vendor-code-search { 
                            width: calc(100% - 46px);
                            float: left;
                            box-sizing: border-box;
                            padding-right: 10px;
                            form { 
                
                                .form-field { 
                                    margin: 0;
                                    .input { 
                                        
                                        .name { 
                
                                        }
                
                                        input { 
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 160%;
                                            height: 40px;
                                            @include placeholder {
                                                font-size: 12px;
                                                font-style: normal;
                                                font-weight: 400;
                                                line-height: 160%;
                                            }
                                        }
                
                                        .addon { 
                                            right: 18px;
                                        }
                                    }
                                }
                            }
                        }
                
                        .filter-button { 
                            width: 46px;
                            height: 40px;
                            float: left;
                            .btn { 
                                width: 100%;
                                height: 100%;
                                box-sizing: border-box;
                                padding: 7px;
                                svg { 
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                    }
                }

                .view-options { 
                    float: right;
                    margin: -3px -4px 11px 0;
                    form { 
                        display: inline-block;
                        width: auto;
                        .form-field { 
                            display: inline-block;
                            width: auto;
                            margin: 0;
                            label.select { 
                                width: auto;
                                .name { 
                                    float: left;
                                    width: auto;
                                    font-size: 12px;
                                    font-family: Manrope;
                                    line-height: 24px;
                                    color: $gray;
                                    margin: 0 3px 0 0;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                }

                                .selectric-selectric-select {
                                    float: left;
                                    padding: 0 0 0 82px;
                                    .selectric {
                                        background: none;
                                        border: none;
                                        border-radius: 0;
                                        box-sizing: border-box;
                                        height: auto;
                                        .label {
                                            font-size: 12px;
                                            font-family: Manrope;
                                            line-height: 24px;
                                            margin: 0 -1px 0 0;
                                            width: auto;
                                            height: auto;
                                            float: left;
                                        }
                                        .button {
                                            position: relative;
                                            left: auto;
                                            top: auto;
                                            float: left;
                                            height: 24px;
                                            width: 24px;
                                            border: none;
                                            &:hover {
                                                background: transparent;
                                            }
                                            &:after {
                                                background-image: url(../img/arrow-24.svg);
                                            }
                                        }
                                    }

                                    .selectric-items {
                                        width: 100% !important;
                                        border-radius: 6px;
                                        background: #FFF;
                                        /* shadow-1 */
                                        box-shadow: 0px 10px 16px 0px rgba(77, 84, 87, 0.10);
                                        .selectric-scroll {
                                            width: 100%;
                                            ul {
                                                width: 100%;
                                                max-height: 310px;
                                                li {
                                                    width: 100%;
                                                    min-height: auto;
                                                    padding: 6px 12px;
                                                    box-sizing: border-box;
                                                    font-size: 12px;
                                                    font-family: Manrope;
                                                    line-height: 140%;
                                                    color: $gray-base;
                                                    &:hover {
                                                        color: $brand-primary;
                                                    }
                                                    &.selected  {
                                                        background: $brand-primary;
                                                        color: white;
                                                        &:hover {
                                                            color: white;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.side-menu { 
    display: inline-block;
    width: 100%;
    nav { 
        display: inline-block;
        width: 100%;
        ul { 
            display: inline-block;
            width: 100%;
            margin: 0;
            li { 
                display: inline-block;
                width: 100%;
                a { 
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 160%;
                    padding: 12px 14px 12px 28px;
                    box-sizing: border-box;
                    border-bottom: 1px solid $bg-secondary;
                    &:hover {
                        background-color: $brand-primary;
                        color: white;
                        .icon { 
                            color: white;
                            transition: all 300ms;
                        }
                    }
                    .text { 
                        max-width: 80%;
                    }

                    .icon { 
                        width: 16px;
                        height: 16px;
                        color: $brand-primary;
                        transition: all 300ms;
                        svg { 
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}

.catalog-list.col-3 {
    .catalog-item {
        width: 33.333%;
    }
}

.catalog-list {
    display: inline-block;
    width: calc(100% + 20px);
    margin-left: -9px;
    .catalog-item {
        width: 25%;
        float: left;
        box-sizing: border-box;
        padding: 0 10px;
        margin: 0 0 17px;
    }
}

@import "../../media/tablet/includes/catalog/list";
@import "../../media/mobile/includes/catalog/list";
