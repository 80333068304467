@media (max-width: $screen-sm) {
    // sm-view
    .text-block { 
        margin: 0 0 38px;
        background: white;
        .text-block-holder { 
            padding: 28px 0 22px;
            border-radius: 0;
            h2, .h2, h3, .h3 {
                margin: 0 0 15px;
            }

            p {
                margin: 0 0 10px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
