@media (max-width: $screen-lg) {
    // lg-md view
    .catalog-column-view { 
        margin: 0 0 25px;
        .catalog-column-view-holder {
            width: 100%;
            margin: 0;
            .column { 
                width: 100%;
                padding: 0;
            }

            .catalog-view { 
                width: 100%;
                padding: 0;
                .catalog-view-holder { 
                    .view-options { 
                        margin: 0 0 15px;
                        float: left;
                    }
                }
            }
        }
    }

    .catalog-list {
        width: calc(100% + 15px);
        margin-left: -7.5px;
        .catalog-item {
            padding: 0 7.5px;
            margin: 0 0 11px;
        }
    }

    .side-menu { 
        margin: 0 0 10px;
        nav {
            ul {
                width: calc(100% + 8px);
                margin-left: -4px;
                li {
                    width: auto;
                    float: left;
                    margin: 0 4px 7px;
                    a {
                        width: auto;
                        padding: 8px 8px;
                        background: white;
                        border-radius: 6px;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 160%;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
