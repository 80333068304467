.controlls { 
    display: inline-block;
    width: 100%;
    margin: -3px 0 0;
    .show-more { 
        display: inline-block;
        width: 100%;
        margin: 0 0 21px;
        .btn { 
            display: inline-block;
            width: 100%;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
            line-height: 160%;
            padding: 13px 10px;
        }
    }

    .pagination-block { 
        display: flex;
        justify-content: center;
        width: 100%;
    }
}

@import "../../media/tablet/includes/common/controlls";
@import "../../media/mobile/includes/common/controlls";
