@media (max-width: $screen-sm) {
    // sm-view
    .detail-tabs { 
        background: white;
        .detail-tabs-holder { 
            padding: 18px 0 9px;
            border-radius: 0;
            .tabs-container { 
                
                .tabs { 
                    .tab { 
                        .params {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
