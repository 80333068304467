@media (max-width: $screen-sm) {
    // sm-view

    .catalog-column-view { 
        margin: 0 0 24px;
        .catalog-column-view-holder {
            .catalog-view { 
                .catalog-view-holder { 
                    .view-options { 
                        margin: 0 0 15px 4px;
                    }
                }
            }
        }
    }

    .catalog-list {
        width: calc(100% + 6px);
        margin-left: -3px;
        .catalog-item {
            padding: 0 3px;
            margin: 0 0 5px;
        }
    }

    .catalog-list.col-xs-2 {
        .catalog-item {
            width: 50%;
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
