@media (max-width: $screen-sm) {
    // sm-view
    .service-order { 
        padding: 30px 4.69% 30px;
        .service-order-holder { 
            .heading {
                margin: 0 0 13px;
            }
        }
    }
}

@media (max-width: $screen-xs) {
    // xs-view
}
