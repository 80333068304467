.catalog-detail { 
    display: inline-block;
    width: 100%;
	.catalog-detail-holder { 
        display: inline-block;
        width: 100%;
        >.name { 
            display: inline-block;
            width: 100%;
            margin: 0 0 30px;
            >* { 
                margin: 0;
            }
        }

        .detail-block { 
            display: flex;
            width: 100%;
            box-sizing: border-box;
            background: white;
            border-radius: 6px;
            overflow: hidden;
            .item-desc { 
                float: left;
                width: 74.6%;
                height: auto;
                .item-desc-holder { 
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    box-sizing: border-box;
                    padding: 30px 40px;
                    border-right: 1px solid $bg-secondary;
                    .item-images { 
                        float: left;
                        width: calc(360px + 50px + 17px);
                        .item-images-holder { 
                            display: inline-block;
                            width: 100%;
                            .images-preview {
                                float: left; 
                                width: 50px;
                                height: 360px;
                                margin-right: 17px;
                                padding: 34px 0 0;
                                box-sizing: border-box;
                                .prev, .next { 
                                    transform: translateX(-50%);
                                    top: 0;
                                    right: auto;
                                    left: 50%;
                                }

                                .next { 
                                    top: auto;
                                    bottom: 0;
                                }
                                
                                .slick-slider {
                                    .slick-list {
                                        .slick-track {
                                            .slick-slide.slick-current {
                                                >div {
                                                   .preview {
                                                        a {
                                                            opacity: 1;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .images-preview-holder { 
                                    height: 100%;
                                    .preview { 
                                        width: 50px;
                                        height: 50px;
                                        margin: 0 0 11px;
                                        a { 
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                            opacity: 0.3;
                                            &:hover {
                                                opacity: 1;
                                            }
                                            img { 
                                                display: inline-block;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: contain;
                                            }
                                        }
                                    }
                                }
                            }

                            .image-view { 
                                float: left; 
                                width: 360px;
                                height: 360px;
                                position: relative;
                                .manufacturer { 
                                    position: absolute;
                                    z-index: 10005;
                                    right: 11px;
                                    top: 10px;
                                    img { 
                                        width: 70px;
                                        height: 36px;
                                        object-fit: contain;
                                    }
                                }

                                .image-view-holder { 
                                    display: inline-block;
                                    width: 100%;
                                    height: 100%;
                                    .image { 
                                        display: inline-block;
                                        width: 100%;
                                        height: 100%;
                                        a { 
                                            display: inline-block;
                                            width: 100%;
                                            height: 100%;
                                            img { 
                                                display: inline-block;
                                                width: 100%;
                                                height: 100%;
                                                object-fit: contain;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .item-details { 
                        float: left;
                        width: calc(100% - (360px + 50px + 17px));
                        .item-details-holder { 
                            display: inline-block;
                            width: 100%;
                            box-sizing: border-box;
                            padding-left: 50px;
                            padding-right: 18px;
                            .vendor-code { 
                                display: inline-block;
                                width: 100%;
                                font-size: 14px;
                                line-height: 160%;
                                color: $gray;
                                margin: 0 0 20px;
                            }

                            .actions { 
                                display: inline-block;
                                width: 100%;
                                .action { 
                                    display: inline-block;
                                    width: 100%;
                                    margin: 0 0 9px;
                                    a { 
                                        display: inline-block;
                                        font-size: 12px;
                                        font-weight: 800;
                                        line-height: 14px;
                                        .text { 
                                            float: left;
                                            max-width: calc(100% - 14px);
                                        }

                                        .icon { 
                                            float: left;
                                            width: 14px;
                                            height: 14px;
                                            color: $brand-primary;
                                            svg { 
                                                width: 100%;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .item-order { 
                float: left;
                width: 25.4%;
                box-sizing: border-box;
                padding: 30px 40px;
                .item-order-holder { 
                    display: inline-block;
                    width: 100%;
                    .labels { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 15px;
                        .label { 
                            float: left;
                            font-size: 11px;
                            font-weight: 800;
                            line-height: 160%;
                            color: white;
                            background: $brand-danger;
                            padding: 3px 6.5px;
                            margin-right: 5px;
                            border-radius: 6px;
                        }
                    }

                    .price { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 14px;
                        .value { 
                            float: left;
                            font-size: 24px;
                            font-weight: 800;
                            line-height: 140%;
                        }
                    }

                    .amount-block { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 16px;
                    }

                    .action { 
                        display: inline-block;
                        width: 100%;
                        margin: 0 0 21px;
                        .btn { 
                            display: inline-block;
                            width: 100%;
                            text-align: center;
                            padding: 10.5px 10px;
                            font-size: 16px;
                            font-weight: 700;
                            line-height: 160%;
                        }
                    }

                    .additional-info { 
                        display: inline-block;
                        width: 100%;
                        .info-line { 
                            display: inline-block;
                            width: 100%;
                            font-size: 12px;
                            line-height: 140%;
                            margin: 0 0 7px;
                            &:last-of-type {
                                margin: 0;
                            }
                            .icon { 
                                float: left;
                                width: 20px;
                                height: 20px;
                                margin-right: 10px;
                                svg { 

                                    path { 

                                    }
                                }
                            }

                            .text { 
                                float: left;
                                line-height: 20px;
                                a { 
                                    text-decoration: underline;
                                    &:hover {
                                        text-decoration-color: transparent;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.params { 
    display: inline-block;
    width: 100%;
    margin: 0 0 27px;
    .params-holder { 
        display: inline-block;
        width: 100%;
        .param { 
            display: flex;
            font-size: 14px;
            line-height: 160%;
            margin: 0 0 5.5px;
            .name { 
                max-width: 45%;
            }

            .line { 
                flex-grow: 1;
                height: 16px;
                border-bottom: 1px dashed $gray-light;
            }

            .value { 
                font-weight: 600;
                a { 
                    color: $brand-primary;
                    &:hover {
                        color: $brand-primary-accent;
                    }
                }
            }
        }
    }
}

@import "../../media/tablet/includes/catalog/detail";
@import "../../media/mobile/includes/catalog/detail";
