.service-order { 
    display: inline-block;
    width: 100%;
    background: white;
    border-radius: 6px;
    box-sizing: border-box;
    padding: 30px;
    overflow: hidden;
	.service-order-holder { 
        display: inline-block;
        width: 100%;
		.heading { 
            display: inline-block;
            width: 100%;
		}

		form { 

			.form-group { 

				.form-col { 

					.form-field { 
                        margin: 0 0 17px;
						.input { 

							.name { 

							}

							input { 

							}

                            textarea {
                                height: 100px;
                            }
						}
					}
				}
			}

			.action { 
                margin: -4px 0 0;
				.submit { 

					.btn { 
                        padding: 13px 10px;
					}
				}

				.agree-col { 

					a { 

					}
				}
			}
		}
	}
}

@import "../../media/tablet/includes/catalog/services_detail";
@import "../../media/mobile/includes/catalog/services_detail";
