.catalog-filter { 
    display: inline-block;
    width: 100%;
	.catalog-filter-holder { 
        display: inline-block;
        width: 100%;
		form { 
            display: inline-block;
            width: 100%;
			.filter-block { 
                display: inline-block;
                width: 100%;
                box-sizing: border-box;
                padding: 28px;
                border-bottom: 1px solid $bg-secondary;
                &:last-of-type {
                    border: none;
                }
				.filter-block-holder { 
                    display: inline-block;
                    width: 100%;
					>.name { 
                        display: inline-block;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 800;
                        line-height: 160%;
                        margin:  0 0 12px;
					}

					.filter-block-contain { 
						display: inline-block;
                        width: 100%;
                        .form-field {
                            margin: 0;
                            label.input { 

                                .name { 
                            
                                }
                            
                                input { 
                                    padding: 0px 30px 0 15px;
                                    font-size: 12px;
                                    line-height: 160%;
                                    @include placeholder {
                                        font-size: 12px;
                                        line-height: 160%;
                                    }
                                }
                            
                                .addon { 
                                    right: 15px;
                                    button { 
                                        color: $gray-base;
                                        &:hover {
                                            color: $brand-primary;
                                        }
                                    }
                                }
                            }
                        }

                        .checkbox-list { 
                            display: inline-block;
                            width: 100%;
                            .checkbox-item { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 18px;
                                &:last-of-type {
                                    margin: 0;
                                }
                                .form-field { 
                        
                                    .checkcontainer { 
                        
                                        input { 
                        
                                        }
                        
                                        .checkmark { 
                        
                                        }
                        
                                        .name { 
                                            font-size: 14px;
                                            line-height: 18px;
                                        }
                                    }
                                }
                            }
                        }

                        .action { 
                            display: inline-block;
                            width: 100%;
                            .submit { 
                                display: inline-block;
                                width: 100%;
                                margin: 0 0 14px;
                                .btn { 
                                    display: inline-block;
                                    width: 100%;
                                    font-size: 14px;
                                    font-weight: 600;
                                    line-height: 160%;
                                    border-radius: 6px;
                                    padding: 13px 10px;
                                }
                            }
                        
                            .reset { 
                                display: inline-block;
                                width: 100%;
                                text-align: center;
                                button { 
                                    font-size: 14px;
                                    line-height: 160%;
                                    margin: 0;
                                    padding: 0;
                                    position: relative;
                                    color: $gray-dark;
                                    background: none;
                                    border: none;
                                    &:hover {
                                        color: $brand-primary;
                                        &:after {
                                            opacity: 0;
                                            transition: all 300ms;
                                        }
                                    }
                                    &:after {
                                        content: "";
                                        display: inline-block;
                                        width: 100%;
                                        position: absolute;
                                        left: 0;
                                        bottom: 2px;
                                        border-top: 1px dashed currentColor;
                                        transition: all 300ms;
                                    }
                                }
                            }
                        }

					}
				}
			}
		}
	}
}

.price-range {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    .represent-text {
        display: flex;
        justify-content: space-between;
        .represent-from, .represent-to {
            float: left;
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 19px;
        }
    }
    .price-inputs {
        display: inline-block;
        width: calc(100% + 10px);
        margin-left: -5px;
        margin-bottom: 0;
        position: relative;
        label {
            float: left;
            width: 50%;
            box-sizing: border-box;
            padding: 0 5px;
            position: relative;
            .name {
                position: absolute;
                left: 21px;
                top: 14px;
                font-size: 14px;
                line-height: 160%;
            }
            input {
                display: flex;
                align-items: center;
                width: 100%;
                height: 50px;
                box-sizing: border-box;
                font-family: $main_font;
                font-size: 14px;
                font-weight: 800;
                line-height: 160%;
                color: $gray-dark;
                background: $bg-secondary;
                border: 1px solid $gray-light;
                padding: 0 5px 0 36px;
                border-radius: 4px;
                transition: all 300ms;
                @include placeholder {
                    font-family: $main_font;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 19px;
                    color: $gray-dark;
                };
                &:focus {
                    outline: none;
                    border-color: $brand-primary;
                    transition: all 300ms;
                }
            }
        }

    }
    .slider-holder {
        display: inline-block;
        width: calc(100% + -10px);
        box-sizing: border-box;
        margin-left: 5px;
        margin-bottom: 19px;
        .noUi-target {
            background: $bg-secondary;
            box-shadow: none;
            border: none;
            &.noUi-horizontal {
                height: 6px;
                .noUi-handle {
                    width: 20px;
                    height: 20px;
                    right: -10px;
                    top: -8px;
                    border: 3px solid $brand-primary;
                    border-radius: 50%;
                    background: white;
                    box-shadow: none;
                    transition: all 300ms;
                    &:focus, &:hover {
                        outline: none;
                        transition: all 300ms;
                    }
                }
            }
            .noUi-base {
                background: $bg-secondary;
                border-radius: 8px;
            }
            .noUi-connect {
                background: $brand-primary;
            }
            .noUi-handle {
                &::after, &::before {
                    content: none;
                }
            }
        }
    }
}