@media (max-width: $screen-lg) {
    // lg-md view
    .services-block { 
        margin: 0 0 39px;
        .services-block-holder { 
            width: 100%;
            margin-left: 0;
            display: flex;
            flex-direction: column-reverse;
            .services-list-block { 
                width: 100%;
                padding: 0;
                .services-list-block-holder { 
                    .services-list { 
                        background: transparent;
                        border-radius: 0;
                        width: calc(100% + 20px);
                        margin-left: -10px;
                        .service { 
                            float: left;
                            width: 50%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            margin:  0 0 22px;
                            .service-holder { 
                                a {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    background: white;
                                    border: none;
                                    height: auto;
                                    flex-wrap: wrap;
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 700;
                                    line-height: 160%;
                                    padding: 18px 30px;
                                    .icon {
                                        width: 100%;
                                        margin: 0 0 22px;
                                    }

                                    .text {
                                        width: 100%;
                                        padding: 0;
                                        height: 104px;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }

                    .controlls { 
                        margin: -25px 0 0;
                    }
                }
            }

            .add-col { 
                width: 100%;
                padding: 0;
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
