@media (max-width: $screen-lg) {
    // lg-md view
    .breadcrumbs {
        margin-top: 21px;
        margin-bottom: 19px;
        overflow: hidden;
        .breadcrumbs-holder {

            .slick-slider {
                .slick-list {
                    overflow: visible;
                    .slick-track {
                        .slick-slide {
                            
                            >div {
                                
                            }
                        }
                    }
                }
            }

            ul.breadcrumbs-list {
                li {
                    margin-right: 8px;
                    &.sep {
                        svg {
                            margin: 1px 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-md) {
    // md-view
}
