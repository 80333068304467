@media (max-width: $screen-lg) {
    // lg-md view
    .controlls {
        margin: 4px 0 0;
    }
}

@media (max-width: $screen-md) {
    // md-view
}
